import { Box } from "@chakra-ui/react"
import React from "react"

const GridElement = ({children, ...boxProps}) => {
  return (
    <Box
      gridRow='1'
      gridColumn='1'
      {...boxProps}
    >
      {children}
    </Box>
  )
}

export default GridElement