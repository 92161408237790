import React from 'react'
import ScrollAnimationWrapper from './ScrollAnimationWrapper'
import VideoZoomInInner from './VideoZoomInInner'

const VideoZoomIn = ({ 
  wrapperHeight,
  videoSrc,
  videoAlt,
  cropFactor
}) => {

  return (
    <ScrollAnimationWrapper 
      stickyChild={true}
      wrapperHeight={wrapperHeight}
    >
      {(
        AnimationValue,
        SetScrollProgressKeyPointStartVpTop,
        SetScrollProgressKeyPointStartVpBottom,
        SetScrollProgressKeyPointTopEdgeViewport
      ) => (
        <VideoZoomInInner 
          AnimationValue={AnimationValue}
          SetScrollProgressKeyPointStartVpTop={SetScrollProgressKeyPointStartVpTop}
          videoSrc={videoSrc}
          videoAlt={videoAlt}
          cropFactor={cropFactor}
        />
      )}
    </ScrollAnimationWrapper>
  )
}

export default VideoZoomIn