
import React from 'react';
import { useRef } from "react";
import { motion, useTransform, useViewportScroll } from "framer-motion";
import { Box } from "@chakra-ui/react";
import useRefScrollProgress from "../DefinitionComponents/Utility/Hooks/useRefScrollProgress";

const MotionBox = motion(Box);

const ScrollAnimationWrapper = ({ wrapperHeight, stickyChild, children }) => {

  const ref = useRef();
  const { startVpTop, startVpBottom, endVpTop, scaledVp, pageHeight } = useRefScrollProgress(ref) //most important hook for this to work. figures out where the ref-element sits in the page
  const { scrollYProgress } = useViewportScroll()
  const blockHeight = endVpTop - startVpTop

  // get the value of the percentage that the component is scrolled
  const SetScrollProgressKeyPointStartVpTop = (factor, subtractVh) => {
    if (!subtractVh) {
      return startVpTop + (blockHeight * factor) // factor of 1 ends at BOTTOM of viewport
    } else {
      return startVpTop + ((blockHeight - (scaledVp / pageHeight)) * factor) // factor of 1 ends at TOP of viewport
    }
  }

  // get the value of the percentage that the component is scrolled
  const SetScrollProgressKeyPointStartVpBottom = (factor, addVh) => {
    if (!addVh) {
      return startVpBottom + (blockHeight * factor) // factor of 1 ends at BOTTOM of viewport
    } else {
      return startVpBottom + ((blockHeight + (scaledVp / pageHeight)) * factor) // factor of 1 ends at TOP of viewport
    }
  }

  // get the position of the top edge of the element in relation to the viewport. 
  // factor of 0: element touches the bottom edge of viewport
  // factor of 1: element touches the top edge of viewport
  const SetScrollProgressKeyPointTopEdgeViewport = (factor) => {
    return startVpBottom + ((scaledVp / pageHeight) * factor)
  }

  // define a value that changes based on scrolling. specify keypoints and keyValues
  // NOTE: only framer-motion-components can use these values, if you want to use them elsewhere
  // set up a useState value
  const AnimationValue = (keyPoints, keyValues) => {
    return useTransform(scrollYProgress, keyPoints, keyValues);
  }

  return (
    <MotionBox
      ref={ref}
      h={wrapperHeight}
      position="relative"
      mx='auto'
      className='scroll-container'
    >
      <MotionBox
        className="item"
        h={stickyChild ? '100vh' : wrapperHeight}
        position={stickyChild ? 'sticky' : 'static'}
        top={stickyChild ? '0px' : ''}
      >
        {children(
          AnimationValue,
          SetScrollProgressKeyPointStartVpTop,
          SetScrollProgressKeyPointStartVpBottom,
          SetScrollProgressKeyPointTopEdgeViewport
        )}
      </MotionBox>
    </MotionBox>
  )
}

export default ScrollAnimationWrapper

