/* --------------------------------------------------

Component: MaskSlideInAnimation

Funktion:
  - Löst eine Masken-Animation aus, wenn Block in den Viewport gelangt.

Beispiel:
  <MaskSlideInAnimation threshold={0.4} duration={1} delay={1}>
    <Children/>
  </MaskSlideInAnimation/>

----------------------------------------------------- */

import React from "react"
import InViewTrigger from "../Utility/UX/InViewTrigger"
import { motion } from "framer-motion"
export default function MaskSlidInAnimation({
  children,
  threshold,
  delay,
  duration,
}) {
  const initial = {
    y: "50%",
    opacity: 0,
  }
  const animate = {
    y: "0%",
    opacity: 1,
    transition: {
      duration: duration,
      delay: delay,
    },
  }
  return (
    <InViewTrigger threshold={threshold}>
      {inView => (
        <motion.div style={{ overflow: "hidden" }}>
          <motion.div initial={initial} animate={inView && animate}>
            {children}
          </motion.div>
        </motion.div>
      )}
    </InViewTrigger>
  )
}
