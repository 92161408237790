import { Container, useColorMode } from "@chakra-ui/react"
import React, { useEffect, useState } from "react"
import BannerBlepharo from "../components/choc/BannerBlepharo"
import BannerHyaloron from "../components/choc/BannerHyaloron"
import BannerVeoJadeklinik from "../components/choc/BannerVeoJadeklinik"
import LogoHeadlineTeaserVeo from "../components/choc/LogoHeadlineTeaserVEO"
import QuadTeaser from "../components/choc/QuadTeaser"
import TitleScrollHeader from "../components/choc/TitleScrollHeader"
import Layout from "../components/layout/Layout"

import TwoColumnGridBoxesAllgemein from "../components/ThreeColumnGridBoxesAllgemein"
const Blank = () => {
  let { toggleColorMode, ColorMode, setColorMode } = useColorMode()
  useEffect(() => {
    setColorMode(colormodeValue)
  })
  const [viewportHeight, setViewportHeight] = useState(0)
  const [pageHeight, setPageHeight] = useState(0)
  useEffect(() => {
    setViewportHeight(window.innerHeight)
    setPageHeight(document.body.clientHeight)
    // console.log('VIEWPORTHEIGHT: ' + window.innerHeight)
    // console.log('PAGEHEIGHT: ' + document.body.clientHeight)
  }, [])
  const [colormodeValue, changeColorModeValue] = useState("light")
  return (
    <Layout
      title={"Willkommen bei VeoMed | JadeKlinik"}
      description={
        "Mund- Kiefer- und Gesichtschirurgie. Ästhetische Behandlungen und Schönheitsoperationen. Schönheits-OP's, Behandlungen und Korrekturen: JadeKlinik in Wilhelmshaven."
      }
    >
      <TitleScrollHeader />
      <LogoHeadlineTeaserVeo
        py={24}
        headline="VeoMed"
        subheadline="Kieferchirurgie und Ästhetik"
      />
      <Container variant="layoutContainer" mt={12}>
        <TwoColumnGridBoxesAllgemein />
      </Container>
      <Container variant="layoutContainer" mt={12}>
        <BannerVeoJadeklinik />
      </Container>
      <BannerBlepharo />
      <BannerHyaloron />
      <LogoHeadlineTeaserVeo
        headline="Ästhetik mit High-Tech"
        subheadline="Und Verantwortung"
      />
      <QuadTeaser />
    </Layout>
  )
}

export default Blank
