import { Box, Container, Heading } from "@chakra-ui/react"
import React from "react"

import FoldUpAnimation from "../../DefinitionComponents/Animation-Components/FoldUpAnimation"
import InViewTrigger from "../../DefinitionComponents/Utility/UX/InViewTrigger"
import GImage from "../GImage"

function LogoHeadlineTeaser({ headline, subheadline, ...restProps }) {
  return (
    <Container {...restProps} borderRadius="2xl" variant="layoutContainer">
      <InViewTrigger threshold={0.4}>
        {inView => (
          <FoldUpAnimation maxW={"7xl"} inView={inView}>
            <Box>
              <GImage
                mx="auto"
                maxW={{ base: "64px", lg: "180px" }}
                src="logo_veo_aest_01.svg"
              />
              <Heading
                fontSize={{ base: "3xl", md: "5xl", lg: "7xl" }}
                color="brand.gray.primary"
                textAlign={"center"}
                fontWeight="normal"
              >
                {headline}
              </Heading>
              <Heading
                fontSize={{ base: "xl", md: "3xl", lg: "5xl" }}
                color="brand.tan.primary"
                textAlign={"center"}
                fontWeight="normal"
              >
                {subheadline}
              </Heading>
            </Box>
          </FoldUpAnimation>
        )}
      </InViewTrigger>
    </Container>
  )
}

export default LogoHeadlineTeaser
