import React from "react"
import { Box } from "@chakra-ui/react"

const GridBox = ({children, ...boxProps}) => {
  return (
    <Box 
      {...boxProps}
      display='grid'
    >
      {children}
    </Box>
  )
}

export default GridBox