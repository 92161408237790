import React, {useState, useEffect} from 'react'
import { motion } from 'framer-motion'
import { Box } from '@chakra-ui/react'
import GVideo from './GVideo'

const MotionBox = motion(Box)

const VideoZoomInInner = ({
    AnimationValue,
    SetScrollProgressKeyPointStartVpTop,
    videoSrc,
    videoAlt,
    cropFactor

}) => {

    const AV = AnimationValue
    const SP = SetScrollProgressKeyPointStartVpTop

    const cropAV = AV([SP(0), SP(0.8, true)], [0, cropFactor])
    const [crop, setCrop] = useState(0)
    useEffect(() => cropAV.onChange((v) => setCrop(v)), [cropAV]);


    return (
        <MotionBox bg={'black'}
            h='100vh' 
            w='100%'
            style={{
                clipPath: `inset(${crop}% ${crop}% ${crop}% ${crop}%)`
            }}
        >
            <GVideo
                opacity={0.7}
                src={videoSrc} 
                alt={videoAlt} 
                css={{ 
                    'video': { 
                        height: '100vh', 
                        width: '100%', 
                        objectFit: 'cover' 
                    } 
                }} 
                h='100% !important' 
                mx='auto' 
            />
        </MotionBox>
    )
}

export default VideoZoomInInner